import { Middleware } from '@nuxt/types';

import { DYNAMIC_CATEGORIES } from '~/components/ProductsListing/config';
import { CMS_PAGE_SLAVERY_IDENTIFIER, PRINTERS_CATEGORY_UID } from '~/constants';
import { Logger } from '~/helpers/logger';
import { getFullUrl } from '~/helpers/urlHelpers';
import { RoutableInterface } from '~/modules/GraphQL/types';
import { usePageStore } from '~/stores/page';

const DYNAMIC_CATEGORY_RESULT = {
  type: 'CATEGORY',
  additionalType: 'PRINTERS_CATEGORY',
  uid: PRINTERS_CATEGORY_UID,
  redirect_code: 0,
};

export interface RouteData {
  uid?: string;
  type?: string;
  additionalType?: string;
  sku?: string;
  identifier?: string;
  canonicalUrl: string;
}

const urlResolverMiddleware : Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path, fullPath } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
  const cmsPageId = context.route.matched.flatMap((route) => Object.values(route.props))?.[0]?.identifier;
  const canonicalUrl = getFullUrl(fullPath);

  Logger.debug('middleware/url-resolver', clearUrl);

  // We don't need to wait for results for preset cms pages
  if (cmsPageId) {
    const cmsPageData = {
      type: 'CMS_PAGE',
      redirect_code: 0,
      identifier: cmsPageId === CMS_PAGE_SLAVERY_IDENTIFIER ? path.replace(/^\//g, '') : cmsPageId,
      canonicalUrl,
    };
    Logger.debug('middleware/url-resolver/result', {
      data: cmsPageData,
      errors: [],
    });
    pageStore.$patch((state) => {
      state.routeData = cmsPageData;
    });

    return;
  }

  const dynamicCategoryUrls = Object.values(DYNAMIC_CATEGORIES).flat().map((category) => category.url_path);

  // Add results for dynamic categories
  if (dynamicCategoryUrls.includes(clearUrl.slice(1))) {
    pageStore.$patch((state) => {
      state.routeData = {
        ...DYNAMIC_CATEGORY_RESULT,
        canonicalUrl,
      };
    });

    Logger.debug('middleware/url-resolver/result', { data: DYNAMIC_CATEGORY_RESULT, errors: [] });

    return;
  }

  const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);

  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = data?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });

  if (results?.redirect_code) {
    context.redirect(results.redirect_code, results.relative_url.startsWith('/') ? results.relative_url : `/${results.relative_url}`);

    return;
  }

  pageStore.$patch((state) => {
    state.routeData = {
      ...results,
      canonicalUrl,
    };
  });
};

export default urlResolverMiddleware;
