import {
  computed,
} from '@nuxtjs/composition-api';

import { useCmsBlocksStore } from '~/stores/cmsBlocks';

import { UseCmsBlocksInterface } from './useCmsBlocks';

export function useCmsBlocks(): UseCmsBlocksInterface {
  const cmsBlocksStore = useCmsBlocksStore();
  const cmsBlocks = computed(() => cmsBlocksStore.cmsBlocks);

  const load = (identifiers: string[], forceUpdate?: boolean) => cmsBlocksStore.load(identifiers, forceUpdate);
  const getCmsBlockByIdentifier = (identifier: string) => cmsBlocksStore.getCmsBlockByIdentifier(identifier);

  return {
    cmsBlocks,
    load,
    getCmsBlockByIdentifier,
  };
}

export default useCmsBlocks;
