
























import { defineComponent, PropType } from '@nuxtjs/composition-api';

import ExternalLink from '~/components/common/ExternalLink.vue';
import { useScrollPosition } from '~/composables';

interface ListItem {
  path: string,
  value: string,
  isExternal?: boolean
}

export default defineComponent({
  name: 'LinksList',
  components: { ExternalLink },
  props: {
    list: {
      type: Array as PropType<ListItem[]>,
      default: () => [],
    },
  },
  setup() {
    const { saveScrollPosition } = useScrollPosition();

    return {
      saveScrollPosition,
    };
  },
});
