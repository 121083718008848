export const loadUserPrintersQuery = `
  query loadUserPrinters($customerEmail: String!) {
    myPrinters(customer_email: $customerEmail)
  }
`;

export const saveUserPrinterMutation = `
  mutation saveUserPrinter($categoryId: String!, $customerEmail: String!, $isActive: Boolean!) {
    addMyPrinter(input: { category_id: $categoryId, customer_email: $customerEmail, is_active: $isActive })
  }
`;
