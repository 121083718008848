






import { computed, defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import { useCmsBlocks } from '~/composables';

export default defineComponent({
  name: 'HomeTestimonials',
  components: {
    HTMLContent,
  },
  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocks();
    const testimonialContent = computed(() => getCmsBlockByIdentifier('home_aw_customer_service'));

    return {
      testimonialContent,
    };
  },
});
