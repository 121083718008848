






import { computed, defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import { useCmsBlocks } from '~/composables';

export default defineComponent({
  name: 'HomeAdvantages',
  components: {
    HTMLContent,
  },
  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocks();
    // const advantagesContent = ;
    // console.log(advantagesContent.value);

    return {
      advantagesContent: computed(() => getCmsBlockByIdentifier('home_usp_2')),
    };
  },
});
