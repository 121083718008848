/* eslint-disable  */

export default {
  "About us": "About us",
  "Account": "Account",
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Clear all": "Clear all",
  "Color": "Color",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "Filters": "Filters",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password?": "Forgotten password?",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Login",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My billing and shipping address are the same": "My billing and shipping address are the same",
  "My Cart": "My Cart",
  "No account": "Don't have an account yet?",
  "or": "or",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Register today",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show":"Show",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "Sort by",
  "Sort: Default": "Default",
  "Sort: Name A-Z": "Name A-Z",
  "Sort: Name Z-A": "Name Z-A",
  "Sort: Price from high to low": "Price from high to low",
  "Sort: Price from low to high": "Price from low to high",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Order Total": "Order Total",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "View": "View",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "The email field must be a valid email":"The email field must be a valid email",
  'You have submitted no reviews': 'You have submitted no reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "My newsletter": "My newsletter",
  "Log out": "Log out",
  "My reviews": "My reviews",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Password change": "Password change",
  "Personal data": "Personal data",
  "Your e-mail": "Your e-mail",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns":"Returns",
  "My orders": "My orders",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first":"Please select a country first",
  "This field is required": "This field is required",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.":"We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "To": "To",
  "Your customization": "Your customization",
  "Passwords don't match":"Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)",
  "Show all products":"Show all products",
  "Select previously saved address":"Select previously saved address",
  "Enter different address":"Enter different address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store":"Change Store",
  "Choose Currency":"Choose Currency",
  "Add a review":"Add a review",
  "Add to cart":"Add to cart",
  "Title":"Title",
  "Name":"Name",
  "Review":"Review",
  "Add review":"Add review",
  "Are you sure you would like to remove this item from the shopping cart?":"Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty":"Your cart is empty",
  "Are you sure?":"Are you sure?",
  "{0} has been successfully removed from your cart":"{0} has been successfully removed from your cart",
  "Amount":"Amount",
  "Thank you for your order!":"Thank you for your order!",
  "Your Purchase":"Your Purchase",
  "Primary contacts for any questions":"Primary contacts for any questions",
  "Your Account":"Your account",
  "What can we improve":"What can we improve",
  "Payment date":"Payment date",
  "The user password was changed successfully updated!":"The user password was changed successfully updated!",
  "The user account data was successfully updated!":"The user account data was successfully updated!",
  "You submitted your review for moderation.": "You submitted your review for moderation.",
  "Starting at": "Starting at",

  "{currentRating} out of {maxRating}":"{currentRating} out of {maxRating}",
  "See all {value} ratings":"See all {value} ratings",
  "Write a customer review":"Write a customer review",
  "To see a full list of products that work in your printer, click on the model name below:":"To see a full list of products that work in your printer, click on the model name below:",
  "No reviews submitted yet":"No reviews submitted yet",
  "Average customer rating:":"Average customer rating:",
  "No reviews have been posted yet. Please don\'t hesitate to share Your opinion and write the first review!":"No reviews have been posted yet. Please don\\'t hesitate to share Your opinion and write the first review!",
  "Learn more":"Learn more",
  "Certain HP printers are intended to work only with cartridges that have a new or reused HP chip or electronic circuitry. These printers use dynamic security measures to block cartridges using a non-HP chip or electronic circuitry. Periodic firmware updates will maintain the effectiveness of these measures and block cartridges that previously worked. Reused HP chips and electronic circuitry enable the use of reused, remanufactured, and refilled cartridges.":"Certain HP printers are intended to work only with cartridges that have a new or reused HP chip or electronic circuitry. These printers use dynamic security measures to block cartridges using a non-HP chip or electronic circuitry. Periodic firmware updates will maintain the effectiveness of these measures and block cartridges that previously worked. Reused HP chips and electronic circuitry enable the use of reused, remanufactured, and refilled cartridges",
  "Add to basket":"Add to basket",
  "Basket":"Basket",
  "SpeedyReorder":"SpeedyReorder",
  "Help":"Help",
  "Contact":"Contact",
  "Rated 4.9 / 5":"Rated 4.9 / 5",
  "Lowest price guaranteed":"Lowest price guaranteed",
  "Fast, FREE next-day delivery on orders over £30":"Fast, FREE next-day delivery on orders over £30",
  "Lowest online price guaranteed":"Lowest online price guaranteed",
  "My account":"My account",
  "Reviews":"Reviews",
  "About this product":"About this product",
  "Specifications":"Specifications",
  "Reasons to buy":"Reasons to buy",
  "Dynamic security enabled printer":"Dynamic security enabled printer",
  "Christmas delivery information":"Christmas delivery information",
  "Order now for dispatch on {value}":"Order now for dispatch on {value}",
  "Order now for dispatch tomorrow":"Order now for dispatch tomorrow",
  "Christmas delivery info":"Christmas delivery info",
  "FREE delivery on everything.":"FREE delivery on everything.",
  "Show prices":"Show prices",
  "Show all":"Show all",
  "brands":"brands",
  "Sign Out":"Sign Out",
  "My returns":"My returns",
  "My saved printers":"My saved printers",
  "Search by printer or cartridge":"Search by printer or cartridge",
  "Home Page":"Home Page",
  "Star": "Star",
  "reviews":"reviews",
  "when you order before {value}":"when you order before {value}",
  "Back to home page":"Back to home page",
  "Home":"Home",
  "Best value": "Best value",
  "Pack of {amount} cartridges":"Pack of {amount} cartridges",
  "{amount} pack":"{amount} pack",
  "per {perPrintType}":"per {perPrintType}",
  "High Capacity":"High Capacity",
  "Page yield:":"Page yield:",
  "For most cartridges this is based on 5% coverage on A4 paper.":"For most cartridges this is based on 5% coverage on A4 paper.",
  "These numbers are based on manufacturer quoted values and are for comparison purposes only.":"These numbers are based on manufacturer quoted values and are for comparison purposes only.",
  "Pack contains":"Pack contains",
  "If within a year of purchase you happen to experience any quality problem, we\'ll happily provide a replacement, or a full refund.":"If within a year of purchase you happen to experience any quality problem, we\'ll happily provide a replacement, or a full refund.",
  "Lowest online price guarantee":"Lowest online price guarantee",
  "Printer protection guarantee":"Printer protection guarantee",
  "Printers that use {value} cartridges":"Printers that use {value} cartridges",
  "12-month warranty":"12-month warranty",
  "3-year warranty":"3-year warranty",
  "Save {price} compared to {brand} brand":"Save {price} compared to {brand} brand",
  "Get {isUpTo} {value} cashback.":"Get {isUpTo} {value} cashback.",
  "UP TO":"UP TO",
  "CASHBACK":"CASHBACK",
  "Get 20% off Cartridge Save cartridges with this printer":"Get 20% off Cartridge Save cartridges with this printer",
  "Professionally refurbished printer":"Professionally refurbished printer",
  "Product works and looks like new. Refurbished by the original manufacturer. Comes with a 1 year warranty.":"Product works and looks like new. Refurbished by the original manufacturer. Comes with a 1 year warranty.",
  "Print Speed: Up to":"Print Speed: Up to",
  "{value} Per Minute - manufacturer quoted values, measured using ISO testing standards for typical print usage, for comparison purposes only.":"{value} Per Minute - manufacturer quoted values, measured using ISO testing standards for typical print usage, for comparison purposes only.",
  "FREE {value} year warranty extension":"FREE {value} year warranty extension",
  "OR":"OR",
  "Trade in your old printer and save £{value}":"Trade in your old printer and save £{value}",
  "New product, not yet available":"New product, not yet available",
  "Out of stock":"Out of stock",
  "Available to buy from {value}":"Available to buy from {value}",
  "Email me when back in stock":"Email me when back in stock",
  "FREE next-day delivery":"FREE next-day delivery",
  "FREE delivery":"FREE delivery",
  "Multi-buy discount":"Multi-buy discount",
  "Buy more,":"Buy more,",
  "save money":"save money",
  "Switch to our own-brand and save":"Switch to our own-brand and save",
  " (Recommended)":" (Recommended)",
  "No thanks, I`m happy with my current selection":"No thanks, I`m happy with my current selection",
  "Prints {altCapacity} pages ({brand} prints {capacity})":"Prints {altCapacity} pages ({brand} prints {capacity})",
  "{qty} added to your basket":"{qty} added to your basket",
  "20% off special offer discount applied":"20% off special offer discount applied",
  "View basket and checkout":"View basket and checkout",
  "Continue shopping":"Continue shopping",
  "Print {yieldDiff}% more and save {priceDiff}": "Print {yieldDiff}% more and save {priceDiff}",
  "Print {yieldDiff}% more for only {priceDiff}": "Print {yieldDiff}% more for only {priceDiff}",
  "Print {yieldDiff}% more for {priceDiff}":"Print {yieldDiff}% more for {priceDiff}",
  "Save {priceDiff}":"Save {priceDiff}",
  "EMAIL ME WHEN BACK IN STOCK":"EMAIL ME WHEN BACK IN STOCK",
  "Out of stock email request":"Out of stock email request",
  "Looking for {type} cartridges?":"Looking for {type} cartridges?",
  " by switching to our Cartridge Save Premium alternative cartridge":" by switching to our Cartridge Save Premium alternative cartridge",
  "{title} {available} for {price}":"{title} {available} for {price}",
  "Switch & Save":"Switch & Save",
  "Why switch to Cartridge Save own-brand?":"Why switch to Cartridge Save own-brand?",
  "Save up to 50% on your printing costs, forever!":"Save up to 50% on your printing costs, forever!",
  "Guaranteed":"Guaranteed",
  "to be a like-for-like alternative to the originals in every way:":"to be a like-for-like alternative to the originals in every way:",
  "Printout quality. Number of prints. Reliability. Printer compatibility. EVERYTHING.":"Printout quality. Number of prints. Reliability. Printer compatibility. EVERYTHING.",
  "By far the best selling third-party printer cartridge in the UK.":"By far the best selling third-party printer cartridge in the UK.",
  "Full 3-year warranty":"Full 3-year warranty",
  "REMEMBER...":"REMEMBER...",
  "all third-party printer cartridges are NOT the same!":"all third-party printer cartridges are NOT the same!",
  "4.8 out of 5 stars":"4.8 out of 5 stars",
  "(from 43,805 customer reviews":"(from 43,805 customer reviews",
  "We publish all product reviews, good and bad. Our exceptionally high customer review score comes from many years of high volume manufacturing experience and rigorous quality control.":"We publish all product reviews, good and bad. Our exceptionally high customer review score comes from many years of high volume manufacturing experience and rigorous quality control.",
  "4.8 out of 5 stars (from 43,805 customer reviews)":"4.8 out of 5 stars (from 43,805 customer reviews)",
  "Our cartridges won’t damage your printer—guaranteed":"Our cartridges won’t damage your printer—guaranteed",
  "Some people worry that own-brand cartridges might damage their printers. We know from experience that ours don’t.":"Some people worry that own-brand cartridges might damage their printers. We know from experience that ours don’t.",
  "Some people whose printers are less than a year old worry that an own-brand cartridge might invalidate the manufacturer’s warranty. This isn’t true. By law, manufacturers aren’t allowed to invalidate your warranty if you use own-brand cartridges. If something does go wrong and our own-brand cartridges are to blame, we’ll take over the manufacturer’s warranty, offer you phone support and repair or replace your printer if needed.":"Some people whose printers are less than a year old worry that an own-brand cartridge might invalidate the manufacturer’s warranty. This isn’t true. By law, manufacturers aren’t allowed to invalidate your warranty if you use own-brand cartridges. If something does go wrong and our own-brand cartridges are to blame, we’ll take over the manufacturer’s warranty, offer you phone support and repair or replace your printer if needed.",
  "To reassure you, we guarantee that we’ll repair or replace your printer—for free—in the unlikely event that it gets damaged by our own-brand cartridge. This is regardless of how old your printer is. We can afford to offer this as problems are almost unheard of.":"To reassure you, we guarantee that we’ll repair or replace your printer—for free—in the unlikely event that it gets damaged by our own-brand cartridge. This is regardless of how old your printer is. We can afford to offer this as problems are almost unheard of.",
  "Your printer warranty is safe":"Your printer warranty is safe",
  "In summary there’s zero risk in using our own-brand cartridges.":"In summary there’s zero risk in using our own-brand cartridges.",
  "Won\'t invalidate your printer warranty":"Won\'t invalidate your printer warranty",
  "Cartridge Save brand 12 month guarantee":"Cartridge Save brand 12 month guarantee",
  "See all {count} reviews":"See all {count} reviews",
  "Customer services":"Customer services",
  "Company info":"Company info",
  "Our guarantees":"Our guarantees",
  "Average Customer Review:":"Average Customer Review:",
  "Whoops":"Whoops",
  ", we couldn't find that page.":", we couldn't find that page.",
  "Why don't you try one of the following:":"Why don't you try one of the following:",
  "Try using our site search for whatever you're looking for.":"Try using our site search for whatever you're looking for.",
  "Browse using the navigation above, or just go to our":"Browse using the navigation above, or just go to our",
  "If you're totally stuck, ":"If you're totally stuck, ",
  "get in touch":"get in touch",
  "SEARCH":"SEARCH",
  "Other cartridges and multipacks in this range":"Other cartridges and multipacks in this range",
  "Something went wrong ...":"Something went wrong ...",
  "We've noticed Internal Server Error while rendering this request.":"We've noticed Internal Server Error while rendering this request.",
  "If you need an assistance you can drop us a line on":"If you need an assistance you can drop us a line on",
  "a contact page":"a contact page",
  "3 reasons why our own-brand cartridges are incredibly reliable":"3 reasons why our own-brand cartridges are incredibly reliable",
  "We are the":"We are the",
  "UK’s biggest seller of own-brand cartridges":"UK’s biggest seller of own-brand cartridges",
  "and have invested heavily in optimising them":"and have invested heavily in optimising them",
  "We are dedicated to quality.":"We are dedicated to quality.",
  "Every own-brand cartridge is performance-tested before it leaves the factory":"Every own-brand cartridge is performance-tested before it leaves the factory",
  "We constantly monitor customer feedback and quickly resolve any issues":"We constantly monitor customer feedback and quickly resolve any issues",
  "Full 3-year warranty on Cartridge Save own-brand":"Full 3-year warranty on Cartridge Save own-brand",
  "We are proud of our own-brand cartridges, so we’ve made trying them a no-brainer. If within three years of purchase you have any problem whatsoever, we’ll happily offer either a replacement or a refund.":"We are proud of our own-brand cartridges, so we’ve made trying them a no-brainer. If within three years of purchase you have any problem whatsoever, we’ll happily offer either a replacement or a refund.",
  "We are confident that no other own-brand cartridges are better than ours":"We are confident that no other own-brand cartridges are better than ours",
  "Give them a try, and see why our customers love them":"Give them a try, and see why our customers love them",
  "{totalItems} results":"{totalItems} results",
  "{totalItems} results for {search}":"{totalItems} results for \"{search}\"",
  "{totalItems} results, page {currentPage} of {totalPages}":"{totalItems} results, page {currentPage} of {totalPages}",
  "printing":"Colour or mono",
  "maximum_iso_a_series_paper":"Paper size",
  "standard_interfaces":"Connectivity",
  "all_in_one_functions":"Functionality",
  "duplex_functions":"Duplex Functionality",
  "compatible_cartridges_available":"Compatible cartridges available",
  "operating_systems_for_filters":"Operating Systems",
  "print_speed_filters":"Print speed",
  "specification_brand":"Manufacturer",
  "specification_brand_type":"Type",
  "specification_product_type":"Category",
  "price":"Price",
  "categories_dynamic":"Printer types",
  "other_brands_dynamic":"Other manufacturers",
  "popular_brands_dynamic":"Popular brands",
  "Item you’re currently viewing":"Item you’re currently viewing",
  "Normal price: {value}":"Normal price: {value}",
  "Special offer price: {value}":"Special offer price: {value}",
  "Save 20% on compatible cartridges when bought with this printer":"Save 20% on compatible cartridges when bought with this printer",
  "Buy together for only":"Buy together for only",
  "You save {value}":"You save {value}",
  "printer:":"printer:",
  "for":"for",
  "We sell this printer for":"We sell this printer for",
  "The following":"The following",
  "{count} products":"{count} products",
  "are guaranteed to work in your {brandTitle} printer:":"are guaranteed to work in your {brandTitle} printer:",
  "Guaranteed to be like-for-like alternatives to the originals.":"Guaranteed to be like-for-like alternatives to the originals.",
  "No products found":"No products found",
  "Please change your search criteria and try again. If you’re still unable to find the correct product for your printer, please ":"Please change your search criteria and try again. If you’re still unable to find the correct product for your printer, please ",
  "contact a member of the team":"contact a member of the team",
  " for further assistance.":" for further assistance.",
  "What {category} does the {group} use?":"What {category} does the {group} use?",
  "{categoryName} Printer Review":"{categoryName} Printer Review",
  "Expert review of the {categoryName} printer":"Expert review of the {categoryName} printer",
  "Our Verdict:":"Our Verdict:",
  "Save this printer to your account so you won\'t have to search for it next time":"Save this printer to your account so you won\'t have to search for it next time",
  "Speedy":"Speedy",
  "Reorder":"Reorder",
  "The printer is saved to your account.":"The printer is saved to your account.",
  "Select your {type} printer":"Select your {type} printer",
  "View {value} printer brands":"View {value} printer brands",
  "Save up to 50% with Cartridge Save own-brand":"Save up to 50% with Cartridge Save own-brand",
  "With over 2 million happy customers, they are by far the best selling third-party printer cartridges in the UK.":"With over 2 million happy customers, they are by far the best selling third-party printer cartridges in the UK.",
  "Complete List of {title}":"Complete List of {title}",
  "FREE next-day delivery on orders over £30":"FREE next-day delivery on orders over £30",
  "Sorry, we couldn\'t find any results for {search}":"Sorry, we couldn\'t find any results for \"{search}\"",
  "Suggestions:":"Suggestions:",
  "Check your spelling":"Check your spelling",
  "Use spaces between words":"Use spaces between words",
  "Try using fewer words":"Try using fewer words",
  "Remember: you can search for your printer model name, or the product code":"Remember: you can search for your printer model name, or the product code",
  "This printer is also available {condition} for {price}":"This printer is also available {condition} for {price}",
  "View cartridges for {name}":"View cartridges for {name}",
  "IN YOUR BASKET":"IN YOUR BASKET",
  "view basket":"view basket",
  "Sort: Relevance":"Sort: Relevance",
  "Price: low to high":"Price: low to high",
  "Price: high to low":"Price: high to low",
  "Next-day delivery":"Next-day delivery",
  "about switch and save":"about switch and save",
  "Choose quantity:":"Choose quantity:",
  "Delivery":"Delivery",
  "Recycling":"Recycling",
  "Resources":"Resources",
  "100% satisfaction":"100% satisfaction",
  "Lowest online price":"Lowest online price",
  "Open a business account":"Open a business account",
  "Schools and local authorities":"Schools and local authorities",
  "Back":"Back",
  "Product Specification":"Product Specification",
  "Data was successfully cleared.": "Data was successfully cleared.",
  "Clear all session data": "Clear all session data",
  "This button will clear your local browser cache and session for Cartridge Save. Including logging you out and wiping your basket contents.": "This button will clear your local browser cache and session for Cartridge Save. Including logging you out and wiping your basket contents.",
};
