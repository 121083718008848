import { onBeforeUnmount, onMounted, ref } from '@nuxtjs/composition-api';

import { DESKTOP_MIN_WIDTH } from '~/constants';
import isServer from '~/helpers/isServer';

export const useMobile = () => {
  const isMobile = ref(false);

  const checkIsMobile = () => {
    if (!isServer) {
      isMobile.value = window.innerWidth < DESKTOP_MIN_WIDTH;
    }
  };

  onMounted(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkIsMobile);
  });

  return { isMobile };
};
