import {
  fragmentAlternativeProduct, fragmentCashback, fragmentPrice, fragmentProduct, fragmentStock,
} from '~/customQueries/productDetails';

export default `
  query productListing($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        products (pageSize: 100) {
          items {
            group
            price_tiers {
              quantity
              final_price {
                value
              }
              final_price_incl_tax {
                value
              }
            }
            yields {
              color
              swatches
              yield_text
              yield
            }
            return_program_hover_over
            return_program_hover_show
            cartridge_condition
            sku_pack_component
            due_in_date
            alternative_product {
              arrow_rule
              price_difference_incl_tax
              price_difference
              yields_difference
              stock_status
              only_x_left_in_stock
              ...AlternativeProduct
              ...Price
              ...Stock
            }
            __typename
            ...BaseProduct
            ...Cashback
            ...Price
            ...Stock
          }
        }
      }
    }
  }
  ${fragmentProduct}
  ${fragmentPrice}
  ${fragmentCashback}
  ${fragmentStock}
  ${fragmentAlternativeProduct}
`;
