

















import {
  defineComponent, onMounted,
  ref,
  useFetch, useRoute,
} from '@nuxtjs/composition-api';

import VatToggler from '~/components/VatToggler/VatToggler.vue';
import {
  useCart,
  useCmsBlocks,
} from '~/composables';
import useMenu from '~/composables/useMenu';
import { APP_ROUTER_PATHS, HOME_BLOCK_IDS } from '~/constants';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree } from '~/modules/GraphQL/types';

import { DesktopNavigation, MobileNavigation } from './Header/Navigation';
import TopBar from './Header/TopBar/TopBar.vue';
import TopNavigation from './Header/TopNavigation/TopNavigation.vue';
import Usps from './Header/Usps/Usps.vue';

let CMS_BLOCK_IDS = ['delivery_replacement_header', 'delivery_replacement_footer', 'footer_company_payment', 'delivery_price_footer'];

export default defineComponent({
  components: {
    TopBar,
    TopNavigation,
    DesktopNavigation,
    Usps,
    VatToggler,
    MobileNavigation,
  },
  setup() {
    const { isAuthenticated } = useUser();
    const { categories: menuList, load: menuListLoad } = useMenu();
    const { load: cmsBlocksLoad } = useCmsBlocks();
    const { load: loadCart } = useCart();
    const categoryTree = ref<CategoryTree[]>([]);
    const route = useRoute();

    // check if it is home and if yes merge blocks
    if (route.value.path === APP_ROUTER_PATHS.home && !CMS_BLOCK_IDS.includes(HOME_BLOCK_IDS[0])) {
      CMS_BLOCK_IDS = [...CMS_BLOCK_IDS, ...HOME_BLOCK_IDS];
    }

    useFetch(async () => {
      await cmsBlocksLoad(CMS_BLOCK_IDS);
      await menuListLoad();

      categoryTree.value = menuList.value;
    });

    onMounted(async () => {
      await loadCart();
    });

    return {
      categoryTree,
      isAuthenticated,
    };
  },
});
