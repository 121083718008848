










import { defineComponent } from '@nuxtjs/composition-api';

import { useScrollPosition } from '~/composables';

export default defineComponent({
  name: 'ExternalLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { saveScrollPosition } = useScrollPosition();

    return {
      saveScrollPosition,
    };
  },
});
