

























import {
  computed, ComputedRef, defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { useAttributes } from '~/composables/useAttributes';
import { isPrinter } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

import { PRINTER_PRODUCT_ATTRIBUTE_LIST, PRODUCT_ATTRIBUTE_LIST } from './config';
import SpecificationIcons from './Icons.vue';
import { ProductSpecification } from './types';

export default defineComponent({
  name: 'Specification',

  components: {
    SpecificationIcons,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    isPrinter: {
      type: Boolean,
    },
  },

  setup(props) {
    const { getOptionLabel } = useAttributes();

    const isPrinterProduct: boolean = isPrinter(props.product) || props.isPrinter;
    const getValue = (key: string) => (props.product[key] ? getOptionLabel(key, `${props.product[key]}`) : null);

    // @TODO: Add fields to gql
    const specification: ComputedRef<ProductSpecification[]> = computed(() => {
      const attributeList = !isPrinterProduct ? PRODUCT_ATTRIBUTE_LIST : PRINTER_PRODUCT_ATTRIBUTE_LIST;

      return attributeList.map(({ attribute_code, attribute_label, icons }) => ({
        title: attribute_label,
        value: getValue(`${attribute_code}`),
        icons,
      })).filter((item) => item.value && item.value !== '0');
    });

    const getIcons = (icons) => (icons.includes(',') ? icons.toLowerCase().split(',').map((icon) => icon.trim()) : [icons.toLowerCase()]);

    return {
      isPrinterProduct,
      specification,
      getIcons,
    };
  },
});
