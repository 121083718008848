




































































import { computed, useCssModule } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { MOBILE_MAX_WIDTH } from '~/constants';
import isServer from '~/helpers/isServer';

export default {
  name: 'SwitchAndSaveBanner',
  components: { SvgImage },
  props: {
    propsStyles: {
      type: Object,
      default: null,
    },
    isLazyImg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const defaultStyles = useCssModule();

    const computedStyles = computed(() => ({ ...defaultStyles, ...props.propsStyles }));
    const isLazyLoad = computed(() => (props.isLazyImg ? !isServer && window.innerWidth <= MOBILE_MAX_WIDTH : false));

    return {
      computedStyles,
      isLazyLoad,
    };
  },
};
