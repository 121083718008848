import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _11cd009e = () => interopDefault(import('../pages/Diagnostic.vue' /* webpackChunkName: "" */))
const _980f2b9c = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _1c492b23 = () => interopDefault(import('../pages/Printers.vue' /* webpackChunkName: "" */))
const _1c2f5092 = () => interopDefault(import('../pages/SwitchAndSave/SwitchAndSave.vue' /* webpackChunkName: "" */))
const _0a9e8c33 = () => interopDefault(import('../pages/ContactUs/ContactUs.vue' /* webpackChunkName: "" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _d61e6f56 = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693a866e = () => interopDefault(import('../pages/Cms/Cms.vue' /* webpackChunkName: "pages/Cms/Cms" */))
const _ef551872 = () => interopDefault(import('../pages/ContactUs/components/Field/Field.vue' /* webpackChunkName: "pages/ContactUs/components/Field/Field" */))
const _8888a5de = () => interopDefault(import('../pages/ContactUs/components/Form/Form.vue' /* webpackChunkName: "pages/ContactUs/components/Form/Form" */))
const _5bccef51 = () => interopDefault(import('../pages/ContactUs/components/Info/Info.vue' /* webpackChunkName: "pages/ContactUs/components/Info/Info" */))
const _64e9356a = () => interopDefault(import('../pages/ContactUs/components/Map/Map.vue' /* webpackChunkName: "pages/ContactUs/components/Map/Map" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _a301d77a,
    name: "checkout",
    children: [{
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping"
    }, {
      path: "billing",
      component: _deb8aae2,
      name: "billing"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you"
    }]
  }, {
    path: "/cart",
    component: _1d513f9d,
    name: "cart"
  }, {
    path: "/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }]
  }, {
    path: "/diagnostic",
    component: _11cd009e,
    name: "diagnostic"
  }, {
    path: "/search",
    component: _980f2b9c,
    name: "search"
  }, {
    path: "/printers.html",
    component: _1c492b23,
    alias: "/printers/:categoryName",
    name: "printers"
  }, {
    path: "/switch-save",
    component: _1c2f5092,
    alias: "/switch-save.html",
    name: "switch-save"
  }, {
    path: "/contact.html",
    component: _0a9e8c33,
    name: "contact-us"
  }, {
    path: "/terms.html",
    component: _731f1fce,
    props: {"identifier":"terms.html"},
    name: "terms"
  }, {
    path: "/privacypolicy.html",
    component: _731f1fce,
    props: {"identifier":"privacypolicy.html"},
    name: "privacypolicy"
  }, {
    path: "/slavery.html",
    component: _731f1fce,
    props: {"identifier":"slavery.html"},
    name: "slavery"
  }, {
    path: "/slavery-archive.html",
    component: _731f1fce,
    props: {"identifier":"slavery-archive.html"},
    name: "slavery-archive"
  }, {
    path: "/slavery-*",
    component: _731f1fce,
    props: {"identifier":"slavery-any-year"},
    name: "slavery-years"
  }, {
    path: "/schools-and-local-authorities.html",
    component: _731f1fce,
    props: {"identifier":"schools-and-local-authorities.html"},
    name: "schools-and-local-authorities"
  }, {
    path: "/lowprice.html",
    component: _731f1fce,
    props: {"identifier":"lowprice.html"},
    name: "lowprice"
  }, {
    path: "/moneyback.html",
    component: _731f1fce,
    props: {"identifier":"moneyback.html"},
    name: "moneyback"
  }, {
    path: "/recycling.html",
    component: _731f1fce,
    props: {"identifier":"recycling.html"},
    name: "recycling"
  }, {
    path: "/aboutus.html",
    component: _731f1fce,
    props: {"identifier":"aboutus.html"},
    name: "about-us"
  }, {
    path: "/free-delivery.html",
    component: _731f1fce,
    props: {"identifier":"free-delivery.html"},
    name: "free-delivery"
  }, {
    path: "/",
    component: _763b5a69,
    alias: "/pwa.html",
    name: "home"
  }, {
    path: "/:slug+",
    component: _731f1fce,
    name: "page"
  }, {
    path: "/Diagnostic",
    component: _11cd009e,
    name: "Diagnostic"
  }, {
    path: "/Faq",
    component: _03c11c08,
    name: "Faq"
  }, {
    path: "/Home",
    component: _763b5a69,
    name: "Home"
  }, {
    path: "/Page",
    component: _731f1fce,
    name: "Page"
  }, {
    path: "/Printers",
    component: _1c492b23,
    name: "Printers"
  }, {
    path: "/Product",
    component: _d61e6f56,
    name: "Product"
  }, {
    path: "/Search",
    component: _980f2b9c,
    name: "Search"
  }, {
    path: "/Cms/Cms",
    component: _693a866e,
    name: "Cms-Cms"
  }, {
    path: "/ContactUs/ContactUs",
    component: _0a9e8c33,
    name: "ContactUs-ContactUs"
  }, {
    path: "/SwitchAndSave/SwitchAndSave",
    component: _1c2f5092,
    name: "SwitchAndSave-SwitchAndSave"
  }, {
    path: "/ContactUs/components/Field/Field",
    component: _ef551872,
    name: "ContactUs-components-Field-Field"
  }, {
    path: "/ContactUs/components/Form/Form",
    component: _8888a5de,
    name: "ContactUs-components-Form-Form"
  }, {
    path: "/ContactUs/components/Info/Info",
    component: _5bccef51,
    name: "ContactUs-components-Info-Info"
  }, {
    path: "/ContactUs/components/Map/Map",
    component: _64e9356a,
    name: "ContactUs-components-Map-Map"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
