



































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { ProductInterface } from '@vue-storefront/magento-types';

import { SimpleImage } from '~/components/common/SimpleImage';
import { useTax } from '~/composables';

export default defineComponent({
  name: 'BestsellerItem',
  components: {
    SimpleImage,
  },
  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
  },
  setup(props) {
    const productImageUrl = computed(() => (props.product.thumbnail ? props.product.thumbnail.url : ''));
    const { vatLabel, getCalculatedPrice } = useTax();
    const productPrice = computed(() => getCalculatedPrice(props.product.price?.regularPrice));

    return {
      productImageUrl,
      vatLabel,
      productPrice,
    };
  },
});
