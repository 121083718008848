


















import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';
import type { TranslateResult } from 'vue-i18n/types';

import Collapsible from '~/components/common/Collapsible.vue';
import { useMobile } from '~/composables/useMobile';

export default defineComponent({
  components: {
    Collapsible,
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
  },

  setup() {
    const { isMobile } = useMobile();

    return {
      isMobile,
    };
  },
});
