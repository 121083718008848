import {
  fragmentCashback, fragmentPrice, fragmentProduct, fragmentStock,
} from '~/customQueries/productDetails';

/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination on printers page.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          __typename
        }
        position
        __typename
      }
      items {
        buy_together {
            sku
        }
        printer_condition
        printer_feature_bullet
        printer_main_functions_bullet
        print_speed_black_normal
        print_speed_colour_normal
        __typename
        ...BaseProduct
        ...Cashback
        ...Price
        ...Stock
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
  ${fragmentProduct}
  ${fragmentCashback}
  ${fragmentPrice}
  ${fragmentStock}
`;
