import parse from 'node-html-parser/dist/parse';

import { ProductReview } from '~/modules/GraphQL/types';

import { UseHelpersInterface } from './useStructuredData';

export function useHelpers(): UseHelpersInterface {
  const getText = (text: string) => {
    if (typeof window === 'undefined') {
      // eslint-disable-next-line
      return parse(text).innerText;
    }

    const HTMLParser = new DOMParser();
    const virtualElement = HTMLParser.parseFromString(text, 'text/html');

    // eslint-disable-next-line
    return virtualElement ? virtualElement.body.innerText : text;
  };

  const removeEmptyFields = (nonProcessedObject: object, nonRequiredFields: string[]) => {
    const result = {};
    const keys = Object.keys(nonProcessedObject);

    // eslint-disable-next-line
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = nonProcessedObject?.[key];
      const isEmptyField = nonRequiredFields.includes(key) && (!value || value === 'undefined' || value === 'null');

      if (!isEmptyField) {
        result[key] = value;
      }
    }

    return result;
  };

  const getReviewJsonLd = (reviews: ProductReview[], name: string) => {
    const result = [];

    // eslint-disable-next-line
    for (let i = 0; i < reviews.length; i++) {
      const item = reviews[i];

      if (Object.keys(item).length > 0 && item.ratings_breakdown[0]) {
        result.push({
          '@type': 'Review',
          author: {
            type: 'Person',
            name: item.nickname?.slice(0, 100),
          },
          datePublished: item.created_at,
          reviewBody: item.text,
          itemReviewed: name,
          reviewRating: {
            '@type': 'Rating',
            worstRating: 0,
            ratingValue: item.ratings_breakdown[0].value,
            bestRating: 100,
          },
        });
      }
    }

    return result;
  };

  return {
    getText,
    removeEmptyFields,
    getReviewJsonLd,
  };
}
