












import {
  computed, defineComponent, onBeforeMount,
} from '@nuxtjs/composition-api';
import Cookies from 'js-cookie';

import SwitchButton from '~/components/SwitchButton/SwitchButton.vue';
import { useTax } from '~/composables';
import isServer from '~/helpers/isServer';

export default defineComponent({
  name: 'VatToggler',
  components: {
    SwitchButton,
  },
  setup() {
    const { taxState, setTax } = useTax();

    const vatValue = computed(() => {
      if (isServer) {
        return taxState.value;
      }

      const magentoVatState = !!Cookies.get('price_display_type');

      if (magentoVatState) {
        const isIncVat = +Cookies.get('price_display_type') === 2;
        setTax(isIncVat);

        return isIncVat;
      }

      return taxState.value;
    });

    const handleSetTax = (value) => {
      setTax(!!value);
      Cookies.set('price_display_type', value ? '2' : '1');
    };

    onBeforeMount(() => {
      setTax(taxState.value);
    });

    return {
      taxState,
      vatValue,
      handleSetTax,
    };
  },
});
