






import { computed, defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import { useCmsBlocks } from '~/composables';

export default defineComponent({
  name: 'HomePopularPrinters',
  components: {
    HTMLContent,
  },
  props: {
    identifier: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { getCmsBlockByIdentifier } = useCmsBlocks();
    const printersBlock = computed(() => getCmsBlockByIdentifier(props.identifier));

    return {
      printersBlock,
    };
  },
});
