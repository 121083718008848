import { Ref, ref, useContext } from '@nuxtjs/composition-api';

import request from './gql/oos.gql';
import { BackInStock } from './useOutOfStock';

export function useOutOfStock() {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const result: Ref<string | null> = ref(null);
  const error: Ref<string | null> = ref(null);

  const requestStockNotification = async (stockDetails: BackInStock) => {
    try {
      loading.value = true;
      const { data, errors } = await app.context.$vsf.$magento.api.customMutation({
        mutation: request,
        mutationVariables: {
          ...stockDetails,
        },
      });

      result.value = data?.requestStockNotification;

      if (!data?.requestStockNotification || (errors && errors.length > 0)) {
        error.value = 'Something went wrong. Please try again later.';
      }
    } catch (error_) {
      error.value = error_;
    } finally {
      loading.value = false;
    }
  };

  return {
    result,
    error,
    requestStockNotification,
  };
}

export * from './useOutOfStock';
export default useOutOfStock;
