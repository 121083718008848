














// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/themes/light.css';

import {
  defineComponent,
} from '@nuxtjs/composition-api';
// @ts-ignore
import { TippyComponent } from 'vue-tippy';

export default defineComponent({
  name: 'Tooltip',

  components: {
    TippyComponent,
  },

  props: {
    interactive: {
      type: Boolean,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'left',
    },
  },
});
