import { CacheTag } from '@vue-storefront/cache';

import { PRINTERS_CATEGORY_ID } from '~/constants';
import { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';

export interface CacheHelpers {
  getCategoryTags: (category: CategoryTree) => CacheTag[],
  getProductTags: (product: ProductInterface) => CacheTag[],
  getListingTags: (products: ProductInterface[], isPrinterPage: boolean) => CacheTag[],
}

export const getCategoryTags = (category: CategoryTree) => {
  const productTags = category.products?.items.reduce((acc, curr) => `${acc} p_${curr.id}`, '') || '';
  const categoryTags = category.children?.reduce((acc, curr) => `${acc} c_${curr.id}`, '') || '';

  return [{ prefix: `cat_c,text,cat_c_${category.id}`, value: `,${productTags}${categoryTags}` }];
};

export const getProductTags = (product: ProductInterface) => {
  const altId = product.alternative_product?.id;

  return [{ prefix: `cat_p,text,cat_p_${product.id}`, value: altId ? `,cat_p_${altId}` : '' }];
};

export const getCmsTags = () => [{ prefix: '', value: 'text' }];

export const getListingTags = (products: ProductInterface[], isPrinterPage: boolean) => {
  const productTags = products?.reduce((acc, curr) => {
    const tag = curr.sku ? 'cat_p' : 'cat_c';

    return `${acc} ${tag}_${curr.id}`;
  }, products?.length ? ',' : '') || '';
  const catId = isPrinterPage ? `,cat_c_${PRINTERS_CATEGORY_ID}` : '';

  return [{ prefix: `cat_c,text${catId}`, value: `${productTags}` }];
};

const cacheHelpers: CacheHelpers = {
  getCategoryTags,
  getProductTags,
  getListingTags,
};

export default cacheHelpers;
