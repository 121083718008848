
















import {
  defineComponent, useRoute, watch,
} from '@nuxtjs/composition-api';

import BrandLinkModal from '~/components/layout/Modals/BrandLinkModal.vue';
import { useUiState } from '~/composables';

import AfterAddToCartModal from './AfterAddToCartModal/AfterAddToCartModal.vue';
import InfoModal from './InfoModal.vue';
import LightBoxImageModal from './LightBoxImageModal.vue';
import ManufacturerModal from './ManufacturerModal.vue';
import NewVisibilityModal from './NewVisibilityModal/NewVisibilityModal.vue';
import OutOfStockModal from './OutOfStockModal/OutOfStockModal.vue';
import ProductPackModal from './ProductPackModal.vue';
import ProtectionModal from './ProtectionModal/ProtectionModal.vue';
import SwitchAndSellModal from './SwitchAndSellModal/SwitchAndSellModal.vue';
import WarrantyModal from './WarrantyModal/WarrantyModal.vue';

export default defineComponent({
  name: 'Modals',
  components: {
    SwitchAndSellModal,
    LightBoxImageModal,
    ProductPackModal,
    InfoModal,
    ProtectionModal,
    WarrantyModal,
    AfterAddToCartModal,
    OutOfStockModal,
    ManufacturerModal,
    BrandLinkModal,
    NewVisibilityModal,
  },
  setup() {
    const { toggleModal } = useUiState();
    const route = useRoute();

    watch(route, () => {
      toggleModal();
    });
  },
});
