























import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'SpecificationIcons',

  components: {
    SvgImage,
  },

  props: {
    icons: {
      type: [Array, null] as PropType<string[] | null>,
      default: null,
    },
  },
});
