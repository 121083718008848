import type { Pagination } from '~/composables/types';
import { sortFiltersByConfig } from '~/helpers/sortFiltersByConfig';
import {
  FILTERS_ORDER,
  FORBIDDEN_SEARCH_FILTERS,
  IS_FILTERS_SHOW_BY_CONFIG,
  IS_FILTERS_SORT_BY_CONFIG,
} from '~/modules/catalog/category/components/filters/config';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { UseFacetSearchResult } from '~/modules/catalog/category/composables/useFacet/useFacet';
import type { Aggregation, ProductInterface } from '~/modules/GraphQL/types';

export interface FacetsGetters {
  getSortOptions: (searchData: UseFacetSearchResult) => SortingModel;
  getProducts: (searchData: UseFacetSearchResult) => ProductInterface[];
  getPagination: (searchData: UseFacetSearchResult) => Pagination;
  getFilters: (searchData: UseFacetSearchResult, isSearchPage?: boolean) => Aggregation[];
}

const facetGetters: FacetsGetters = {
  getSortOptions(searchData) {
    if (!searchData || !searchData.data || !searchData.data.availableSortingOptions) {
      return {
        options: [],
        selected: '',
      } as SortingModel;
    }

    return {
      options: searchData.data.availableSortingOptions,
      selected: searchData.input.sort,
    };
  },
  getProducts(searchData) {
    if (!searchData || !searchData.data || !searchData.data.items) {
      return [];
    }
    return searchData.data.items;
  },
  getFilters(searchData, isSearchPage?: boolean) {
    let filters = [];

    if (!searchData || !searchData.data || !searchData.data.aggregations) {
      return filters;
    }

    filters = searchData.data.aggregations;

    if (isSearchPage) {
      filters = searchData.data.aggregations.filter((filter) => (IS_FILTERS_SHOW_BY_CONFIG
        ? !FORBIDDEN_SEARCH_FILTERS.includes(filter.attribute_code) && FILTERS_ORDER.includes(filter.attribute_code)
        : !FORBIDDEN_SEARCH_FILTERS.includes(filter.attribute_code)));
    }

    if (IS_FILTERS_SORT_BY_CONFIG) {
      return sortFiltersByConfig(filters, FILTERS_ORDER);
    }

    return filters;
  },
  getPagination(searchData) {
    const totalPages = (searchData?.data) ? (
      Number.isNaN(Math.ceil(searchData.data.total / searchData.input.itemsPerPage))
        ? 1
        : Math.ceil(searchData.data.total / searchData.input.itemsPerPage)
    ) : 1;

    return {
      currentPage: (searchData?.input?.page > totalPages ? 1 : searchData?.input?.page) || 1,
      totalPages: searchData?.data?.totalPages || totalPages,
      totalItems: (searchData?.data?.total) ? searchData.data.total : 0,
      itemsPerPage: searchData?.input?.itemsPerPage || 10,
      pageOptions: perPageOptions,
    };
  },
};

export default facetGetters;
