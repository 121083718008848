import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: false, // Track loading state
  }),
  actions: {
    setLoading(value) {
      this.isLoading = value;
    },
  },
});
