import { readonly, ref } from '@nuxtjs/composition-api';

import { useApi } from '~/composables/useApi';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';

import { loadUserPrintersQuery, saveUserPrinterMutation } from './myPrinters.gql';
import {
  MyPrintersLoadParams, MyPrintersResultData,
  MyPrintersSaveParams, UseMyPrintersInterface,
} from './useMyPrinters';

export function useMyPrinters(): UseMyPrintersInterface {
  const customerStore = useCustomerStore();

  const { query, mutate } = useApi();
  const loading = ref(false);

  const loadPrinters = async (params?: MyPrintersLoadParams) => {
    Logger.debug('useMyPrinters/load', params);

    try {
      loading.value = true;

      const { data } = await query<MyPrintersResultData>(loadUserPrintersQuery, params);
      const myPrinters = data?.myPrinters ?? [];
      Logger.debug('[Result]:', { myPrinters });

      customerStore.setSavedPrinters(myPrinters);
    } catch (err) {
      Logger.error('useMyPrinters/load', err);
    } finally {
      loading.value = false;
    }
  };

  const savePrinter = async (params?: MyPrintersSaveParams) => {
    Logger.debug('useMyPrinters/save', params);

    try {
      loading.value = true;

      await mutate(saveUserPrinterMutation, {
        isActive: true,
        ...params,
      });

      // TODO: Remove when will be added a result for addMyPrinter mutation
      await loadPrinters({ customerEmail: params.customerEmail });
    } catch (err) {
      Logger.error('useMyPrinters/save', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loadPrinters,
    savePrinter,
    loading: readonly(loading),
  };
}

export default useMyPrinters;
export * from './useMyPrinters';
