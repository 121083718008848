import { computed } from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal/constants';
import { useUiState } from '~/composables';
import { isInThePast } from '~/helpers/dateCompare';
import { ProductInterface } from '~/modules/GraphQL/types';

import { UseCashbackInterface } from './useCashback';

export function useCashback(product: ProductInterface): UseCashbackInterface {
  const { toggleModal } = useUiState();

  const isUpTo = computed(() => !!(+product.bullet_upto_visibility));
  const hasCashback = computed(() => {
    const cashBackExists = product.cashback_catch && product.cashback_value;

    if (!product?.cashback_end_date) {
      return false;
    }

    // If end already was
    if (isInThePast(product?.cashback_end_date)) {
      return false;
    }

    // If end already was
    if (product?.cashback_start_date && !isInThePast(product.cashback_start_date)) {
      return false;
    }

    return !!cashBackExists;
  });

  const openModal = () => {
    if (!product.cashback_lightbox_html) {
      return;
    }

    toggleModal({
      identifier: MODAL_NAMES.info,
      props: {
        content: product.cashback_lightbox_html,
      },
    });
  };

  return {
    isUpTo,
    hasCashback,
    openModal,
  };
}

export * from './useCashback';
export default useCashback;
