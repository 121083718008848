

































import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import NewVisibilityBullets from '~/components/ProductBullets/NewVisibilityType/NewVisibilityType.vue';
import Specification from '~/components/Specification/Specification.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'NewVisibilityModal',
  components: {
    Specification,
    SimpleModal,
    NewVisibilityBullets,
  },
  setup() {
    const { modal } = useUiState();

    const product = computed(() => modal.value.props?.product || {});
    const compatibilityBrand = computed(() => modal.value.props?.brand);

    return {
      product,
      compatibilityBrand,
      name: MODAL_NAMES.newVisibility,
    };
  },
});
