




























































































































































import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useAttributes } from '~/composables';

export default defineComponent({
  name: 'SwitchAndSellInfo',
  components: {
    SvgImage,
  },

  props: {
    info: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { getOptionLabel } = useAttributes();

    const brand = computed(() => getOptionLabel('specification_brand', `${props.info.brand}`));

    return {
      brand,
    };
  },
});
