
























import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfAddressPicker } from '@storefront-ui/vue';

import UserAddressDetails from '~/components/UserAddressDetails.vue';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import type { Country, CustomerAddress } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'UserBillingAddresses',
  components: {
    SfAddressPicker,
    UserAddressDetails,
  },
  props: {
    currentAddressId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    billingAddresses: {
      type: Array as PropType<CustomerAddress[]>,
      required: true,
    },
    countries: {
      type: Array as PropType<Country[]>,
      default: () => [],

    },
  },
  emits: ['setCurrentAddress'],
  setup(props, { emit }) {
    const emitSetCurrentAddress = (addressId: number) => {
      const address = props.billingAddresses.find(({ id }) => id === Number(addressId));
      if (address) {
        emit('setCurrentAddress', address);
      }
    };
    const addressesWithCountryName = computed(() => props.billingAddresses.map((address) => ({
      ...address,
      countryName: props.countries
        .find(({ id }) => id === address.country_code)
        ?.full_name_locale
        ?? address.country_code,
    })));

    return {
      emitSetCurrentAddress,
      addressesWithCountryName,
      userBillingGetters,
    };
  },
});
