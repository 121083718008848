import { onMounted } from '@nuxtjs/composition-api';

export const useScrollPosition = () => {
  const saveScrollPosition = () => {
    const savedPositions = JSON.parse(localStorage.getItem('scrollPositions') || '{}');
    savedPositions[window.location.href] = window.scrollY;
    localStorage.setItem('scrollPositions', JSON.stringify(savedPositions));
  };

  const restoreScrollPosition = (url: string) => {
    const savedPositions = JSON.parse(localStorage.getItem('scrollPositions') || '{}');
    const position = (savedPositions[url] || 0) as number;

    if (position) {
      window.scrollTo(0, position);
    }

    localStorage.removeItem('scrollPositions'); // Обновляем localStorage
  };

  onMounted(() => {
    const currentUrl = window.location.href;
    restoreScrollPosition(currentUrl);
  });

  return {
    saveScrollPosition,
    restoreScrollPosition,
  };
};
