






































import {
  defineComponent, ref,
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

import { useOutOfStock, usePageHelpers } from '~/composables';

extend('email', {
  ...email,
  message: 'Please provide a valid e-mail address.',
});

extend('required', {
  ...required,
  message: 'Field is required',
});

export default defineComponent({
  name: 'OutOfStockForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    SfInput,
  },
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
    categoryId: {
      type: [String, Number],
      default: null,
    },
  },

  emits: ['is-success'],

  setup(props, { emit }) {
    const { requestStockNotification, result, error } = useOutOfStock();
    const { isSearchPage } = usePageHelpers();

    const form = ref({
      email: '',
    });
    const loading = ref(false);

    const submitForm = async () => {
      error.value = '';
      loading.value = true;

      await requestStockNotification({
        email: form.value.email,
        productId: props.productId,
        categoryId: props.categoryId,
        categoryProduct: !isSearchPage && props.categoryId ? 'category' : 'product',
      });

      if (result?.value) {
        emit('is-success');
      }

      loading.value = false;
    };

    return {
      form,
      error,
      loading,
      submitForm,
    };
  },
});
