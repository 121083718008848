
























import { defineComponent, useContext, useRoute } from '@nuxtjs/composition-api';
import ClientOnly from 'vue-client-only';
import LazyHydrate from 'vue-lazy-hydration';

import IconSprite from '~/components/General/IconSprite.vue';
import AppFooter from '~/components/layout/AppFooter.vue';
import AppHeader from '~/components/layout/AppHeader.vue';
import Modals from '~/components/layout/Modals/Modals.vue';
import Overlay from '~/components/layout/Overlay/Overlay.vue';
import ZendeskButton from '~/components/ZendeskButton.vue';
import { useScrollPosition, useUiState } from '~/composables';
import { useAttributes } from '~/composables/useAttributes';
import isServer from '~/helpers/isServer';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LazyHydrate,
    AppHeader,
    IconSprite,
    AppFooter,
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    Overlay,
    Modals,
    ZendeskButton,
    ClientOnly,
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, isOverlay,
    } = useUiState();
    const { $config } = useContext();

    const { load: loadAttributes } = useAttributes();
    useScrollPosition();

    loadAttributes();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      route,
      isOverlay,
      isServer,
      toggleLoginModal,
    };
  },
  head: {
    link: [
      // {
      //   rel: 'preload',
      //   href: '/_nuxt/fonts.css',
      //   as: 'style',
      //   onload: 'this.onload=null;this.rel=\'stylesheet\'',
      // },
    ],
    script: isServer ? [
      {
        src: 'https://consent.cookiebot.com/uc.js',
        async: true,
        defer: false,
        body: false, // Ensures it is in the <head> section
        'data-cbid': '5b4bedf8-1afb-4b09-b2a1-fc93307ee38f',
        'data-blockingmode': 'consent',
      },
    ] : [],
    noscript: [
      { innerHTML: '<link rel="stylesheet" href="/_nuxt/fonts.css">' },
    ],
  },
});
