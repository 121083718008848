import { readonly, ref } from '@nuxtjs/composition-api';

import useApi from '~/composables/useApi';
import { Logger } from '~/helpers/logger';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { Products } from '~/modules/GraphQL/types';

import getFacetDataQuery from './getFacetData.gql';
import getSearchDataQuery from './getSearchData.gql';
import type {
  FacetSearchParams,
  UseFacetErrors, UseFacetInterface, UseFacetSearchResult,
} from './useFacet';

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet(): UseFacetInterface {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const error = ref<UseFacetErrors>({
    search: null,
  });
  const defaultItemsPerPage = 20;
  const search = async (params: FacetSearchParams = {}) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;
      const dataQuery = params.isSearchPage ? getSearchDataQuery : getFacetDataQuery;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.p || params.page,
      };

      const { data } = await query<{ products: Products }>(dataQuery, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        items: products?.items ?? [],
        aggregations: products?.aggregations ?? [],
        total: products?.total_count,
        totalPages: data?.products.page_info?.total_pages,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
        redirectUrl: data.products.page_info.redirect_url,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
